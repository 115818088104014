import React, { useState } from 'react';
import './index.css';

const QuizApp = () => {
  

  return (
    <>
    <div className='question-wrapper'>
        <div className='question-card'>
            <div className='question-in-wrapper'>
                <div className='question-info'>
                  <span className='question-order'></span>
                  <div className='question-title-wrapper'>
                    <div className='question-title'>
                      <p className='q-title-p'>What would you like to do this weekend?</p>
                    </div>
                    <p  placeholder="Description" className="question-description editable-input view">
										  Select an activity to do as a team. Let's go;
									  </p>
                  </div>
                </div>
                <div className='question-body'>
                  <div className='input-choice'>
                    <label className='i-checkbox checkbox form-input-choice'>
                      <input type='checkbox' hidden />
                      <span>
                        <i className='before-checkbox'></i>
                        <p className='label-text'>Biking is good think</p>
                      </span>
                    </label>
                  </div>
                </div>

            </div>
        </div>
    </div>
    </>
  );
}

export { QuizApp };
