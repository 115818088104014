import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentComponent = ({ clientSecret, cartItems, validateCheckoutForm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [userToken, setUserToken] = useState('');
  const [appearance, setAppearance] = useState({
    theme: 'flat',
    variables: { 
      colorPrimary: '#e2b9b3', 
      colorPrimaryText: '#262626',
    },
  });
  const [options, setOptions] = useState({
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
    business: {
      name: 'RocketRides', 
    },
    appearance: appearance,
    // fields: {
    //   billingDetails: {
    //     address: {
    //       country: 'never', // Hide country field
    //       postalCode: 'never', // Hide postcode field
    //     },
    //   },
    // },
  });
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUrl = `${process.env.REACT_APP_BASE_URL}/appointment/confirmation`;

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = validateCheckoutForm(); 

    if (!isFormValid) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage('');

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
      redirect: 'if_required',
    });

    if (error) {
      // Display error message to your customers
      setErrorMessage(error.message);
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const token = cartItems[0].userToken || '';
      setUserToken(token);
    }
  }, [cartItems]);

  return (
    <form onSubmit={handlePaymentSubmit}>
      <div id="payment-element mt-4">
        <PaymentElement options={options} />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button type="submit" className="btn-show w-100 metropill" disabled={!stripe || isProcessing}>
          {isProcessing ? 'Processing...' : 'PAY'}
        </button>
      </div>
    </form>
  );
};

export default PaymentComponent;
