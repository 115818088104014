import React, { useState, useEffect } from 'react'
import './index.css'
import { Link } from 'react-router-dom';
import DrawerComponent from './Drawer';
import { get } from '../../../services/ApiService';
import TextWithReadMore from '../../../components/TextWithReadMore';

const CardsComponent = ({ data,isNotFound, selectedServiceType, handleAdd, handleRemove, isItemSelected, setSearchValue,  searchValue, handleTypeChange, biomarkerData, isLoad }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBiomarker, setSelectedBiomarker] = useState(null);
  const [biomarkers, setBiomarkers] = useState([]);

  useEffect(() => {
    fetchBiomarkers();
  }, []);

  const fetchBiomarkers = async () => {
    try {
      const res = await get('api/package/biomarkers');
      if (res.success) {
        setBiomarkers(res.biomarkers);
      } else {
        setBiomarkers([]);
      }

    } catch (error) {
        setBiomarkers([]);
    }
  };



  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedBiomarker(null);
    document.body.style.overflow = 'auto';
  };

  const handleBiomarkerClick = (item) => {
    const biomarkersToShow = item.type === 'bio' ? [item._id] : item.biomarkers;
    const fetchedBiomarkers = biomarkerData.filter(biomarker => biomarkersToShow.includes(biomarker._id));
    setSelectedBiomarker(fetchedBiomarkers);
    setDrawerOpen(true);
    document.body.style.overflow = 'hidden';

};

  return (
    <div className='mt-5 px-lg-4'>
      <div className='row g-5'>
        <div className='col-lg-12'>
          <h4 className='py-5 my-5' style={{fontSize:'1.88889rem'}}>Build My Package</h4>
          <p className='py-5 fw-normal metropill my-5' style={{fontSize:'18px'}}>
                Explore our range of biomarkers and profiles. Then, click 'Build My Package,' and we'll recommend the most suitable and cost-effective package tailored to your needs.
          </p>
          <div>

            <div className='mt-5 pt-5 d-none d-lg-block'>
                <div class="input-group search-input">
                <input
                    name="search"
                    type="search"
                    className="form-control"
                    placeholder="Search Here"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter" || event.code === "Search" || event.key === "Search") {
                            event.target.blur(); // This will hide the keyboard on mobile
                        }
                    }}
                />
                    <div class="input-group-addon">
                        <label for='searchid' class="border-0">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.21141 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.21141 17.5 9.58366 17.5Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M18.3337 18.3334L16.667 16.6667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </label>
                    </div>
                </div>
            </div>

            <div className='d-flex gap-3 mt-5 pt-5'>
                <button className={selectedServiceType === 'all' ? 'btncust-tb activebg' : 'btncust-tb'} onClick={()=>handleTypeChange('all')}>
                    All
                </button>
                <button className={selectedServiceType === 'bio' ? 'btncust-tb activebg' : 'btncust-tb'} onClick={()=>handleTypeChange('bio')}>
                    Biomarkers
                </button>
                <button className={selectedServiceType === 'pro' ? 'btncust-tb activebgpro' : 'btncust-tb'} onClick={()=>handleTypeChange('pro')}>
                    Profiles
                </button>
            </div>
          </div>

        </div>
        <hr />
        {isLoad && 
          <div className='col-md-12 text-center' style={{marginTop:'50px'}}>
              <span className="conslut-loading"></span>
          </div>
        }
        {!isLoad && data && data.length > 0 &&
          data.map((item, index) => (
            <div className='col-lg-4' key={index} >
              <div className={`card rounded-4 shadow-c position-relative border-0 p-4 ${item.type === 'bio' ? 'border-main':'border-pro'}`}>
                <div className='card-body'>
                  <div>
                    <div className='c-head' style={{height:'100px',overflow:'hidden'}}>
                      <h4 className='fw-normal' title={item.type === 'bio' ? item.biomaker : item.name} >
                        {item.type === 'bio' ? item.biomaker : item.name}
                      </h4>
                    </div>
                    <TextWithReadMore
                        text={item?.description}
                        wordLimit={150}
                    /> 
                    <div className='d-flex justify-content-center'>
                      <Link className="tag-btn3 text-decoration-none text-capitalize gap-3" onClick={() => handleBiomarkerClick(item, selectedServiceType)}> 
                        <span className='fw-semibold'>{item.type === 'bio' ? 1 : item.biomarkers.length}</span>
                        Biomarker Analysed
                      </Link>
                    </div>
                    <div className='d-flex justify-content-end'>
                      {isItemSelected(item._id) ? (
                      <button type='button' className={`${item.type === 'pro' ? 'btn-green active':'btn-show active'}`} onClick={() => handleRemove(item._id)}>
                        Remove
                      </button>
                      ):(
                        <button type='button' className={`${item.type === 'pro' ? 'btn-green':'btn-show'}`} onClick={() => handleAdd(item._id, item.type)}>
                          Add
                        </button>
                      )}

                    </div>
                  </div>                    
                </div>
              </div>
            </div>
          ))
        }
        {isNotFound && 
          <div className='col-12 text-center'>
            <h4 className='fw-normal'>OOPS!</h4>
            <p className='fw-normal '>No results found. Alternatively, kindly check the spelling. </p>
          </div>
        }

      <DrawerComponent
        isOpen={drawerOpen}
        onClose={closeDrawer}
        biomarkerData={selectedBiomarker}
      />
      </div>
    </div>

    
  )
}

export {CardsComponent}