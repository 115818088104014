import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './index.css';
import Spinner from 'react-bootstrap/Spinner';
import { loadStripe } from '@stripe/stripe-js'; // Properly import loadStripe
import {  Elements } from '@stripe/react-stripe-js';
import { post } from '../../services/ApiService.js';
import PaymentComponent from './PaymentComponent';

function OrderSummary({
  handleSubmit,
  cartItems,
  calculateSubtotal,
  calculateTotal,
  isSubmit,
  isBloodDraw,
  filteredTimeSlots,
  selectedDate,
  selectedTimeSlot,
  validateCheckoutForm
}) {
  const stripePromise = loadStripe('pk_test_51IjhJTFHLnGWPWc3sdTdCkcNo3pBg55BaWV6M9oOkv6z9KSe1aZdvVKtVXICL13G1m3GbnevZTlY3qLOQ26yzCfo00H53DuljF');
  const [clientSecret, setClientSecret] = useState('');
  // const elements = useElements();

  useEffect(() => {
    const paymentIntent = async () => {
      const body = {
        amount: calculateTotal() * 100,
        currency: 'gbp',
      };
      const res = await post('api/booking/create-payment-intent', body );
      console.log(res)
      if (res.success) {
        setClientSecret(res.clientSecret);
      }
    };
    paymentIntent();
  }, [calculateTotal]);

  // const handlePaymentSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!elements || !clientSecret) {
  //     console.error('Elements or clientSecret is not set');
  //     return;
  //   }

  //   const result = await elements.confirmPayment({
  //     payment_method: {
  //       type: 'card',
  //       card: elements.getElement(CardElement),
  //     },
  //     confirm: true,
  //     clientSecret,
  //   });

  //   if (result.error) {
  //     console.error('Payment error:', result.error.message);
  //   } else if (result.paymentIntent.status === 'succeeded') {
  //     console.log('Payment successful:', result.paymentIntent);
  //     handleSubmit(); // Submit order data (e.g., call an API)
  //   }
  // };


  return (
    <>
      <div className='card order-summary'>
        <div className='card-body'>
          {/* Order details */}
          <div className='row'>
            <div className='col-12'>
              <h4 style={{ marginBottom: '20px' }}>Order Summary</h4>
            </div>
            <div className='col-4 text-dark mb-4'>Date:</div>
            <div className='col-8 mb-4 metropill text-body-emphasis text-end' style={{ fontWeight: '600' }}>
              {selectedDate} {selectedTimeSlot}
            </div>
            <div className='col-4 mb-4 text-dark'>Provider:</div>
            <div className='col-8 mb-4 metropill text-body-emphasis text-end' style={{ fontWeight: '600' }}>My Private Chemist, Longcraft House, 2-8 Victoria Avenue, EC2M 4NS</div>
          </div>
          <hr />
          {/* Cart items */}
          {cartItems.length > 0 && (
            <>
              {cartItems.map((item, index) => (
                <div className='d-flex flex-grow-1' key={index}>
                  <div className="pe-6 theme-font text-dark">
                    {item.name}
                  </div>
                  <div className="ms-auto">
                    <p className="fs-14px text-body-emphasis mb-0 fw-normal metropill">
                      £{item.type === "Service" ? item.price : item.discountPrice}
                    </p>
                  </div>
                </div>
              ))}
              <hr />
            </>
          )}
          {/* Subtotal */}
          <div className="d-flex align-items-center mb-2 theme-font">
            <span className="theme-font  text-dark">Subtotal:</span>
            <span className="d-block ms-auto text-body-emphasis fw-normal metropill">
              £{calculateSubtotal()}
            </span>
          </div>
          {/* Blood Draw fee (if applicable) */}
          {isBloodDraw && (
            <div className="d-flex align-items-center mb-2 theme-font">
              <span className="theme-font  text-dark">Blood Draw fee:</span>
              <span className="d-block ms-auto text-body-emphasis  fw-normal metropill">
                £30
              </span>
            </div>
          )}
        </div>
        {/* Total */}
        <div className="card-footer bg-transparent py-5 px-0 mx-10">
          <div className="d-flex align-items-center fw-bold mb-6">
            <span className="text-body-emphasis p-0 theme-font fw-bold" style={{ fontSize: '20px' }}>
              Total price:
            </span>
            <span className="d-block ms-auto text-body-emphasis fw-bold metropill" style={{ fontSize: '20px' }}>
              £{calculateTotal()}
            </span>
          </div>
        </div>
      </div>
      {/* Payment options */}
      <div className="d-flex mt-8 text-center" style={{ marginTop: '30px !important' }}>
        <div className="col-auto me-2">
          <img className="w-60" src="/assets/images/payments/card1.png" alt='' />
        </div>
        <div className="col-auto me-2">
          <img className="w-70" src="/assets/images/payments/card2.png" alt='' />
        </div>
        <div className="col-auto me-2">
          <img className="w-70" src="/assets/images/payments/card3.png" alt='' />
        </div>
        <div className="col-auto me-2">
          <img className="w-70" src="/assets/images/payments/card4.png" alt='' />
        </div>
        <div className="col-auto me-2">
          <img className="w-70" src="/assets/images/payments/card5.png" alt='' />
        </div>
      </div>
      {/* Payment button */}
      <div className="text-center mt-4">
        {/* {filteredTimeSlots.length > 0 && (
              <button type="submit" className="btn-show w-100 metropill" disabled={isSubmit}>
                {isSubmit && <Spinner animation="border" variant="light" size="sm" className="mr-2" />} PAY
              </button>

        )} */}

        {selectedTimeSlot && clientSecret && stripePromise && 
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentComponent clientSecret={clientSecret} cartItems = {cartItems} validateCheckoutForm={validateCheckoutForm} />
          </Elements>
        }
       
      </div>
    </>
  );
}

export default OrderSummary;
