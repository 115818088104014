import React, { useState, useEffect, useRef } from "react";
import { post } from '../../services/ApiService.js';
import TimeSlotList from "./TimeSlots";
import Navbar from "../../components/Navbar/index.jsx";
import Footer from "../../components/Footer.jsx"
import BillingDetail from "./BillingDetail.jsx";
import BookingDatePicker from "./BookingDatePicker.jsx";
import OrderSummary from "./OrderSummary.jsx";
import PageLoader from "../../components/PageLoader.js";
import {loadStripe} from '@stripe/stripe-js';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { BreadCrumb } from "../../components/Navbar/BreadCrumb.js";
import {  Elements } from '@stripe/react-stripe-js';
const Checkout = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(true); 
  const containerRef = useRef(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isTimeSlotErr, setIstimeSlotErr] = useState(false);
  const timeSlotsRef = useRef(null);
  const [userToken, setUserToken] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [consistentTimeSlots, setConsistentTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false); 
  const [isDateSelected, setIsDateSelected] = useState(false); 
  const [selectedDate, setSelectedDate] = useState(''); 
  const [isSubmit, setIsSubmit] = useState(false); 
  const [dateSelectLoading, setDateSelectLoading] = useState(false); 
  const [isOver18, setIsOver18] = useState(true);
  const [isBloodDraw, setIsBloodDraw] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const firstErrorRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nineDigitRegex = /^\d{9,}$/;
  // const stripePromise = loadStripe('pk_test_51IjhJTFHLnGWPWc3sdTdCkcNo3pBg55BaWV6M9oOkv6z9KSe1aZdvVKtVXICL13G1m3GbnevZTlY3qLOQ26yzCfo00H53DuljF');

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email:'',
    phone:'',
    dob:'',
    gender:'',
    date:'',
    time:'',
    slots:[],
    userToken:''
  });

  useEffect(() => {
    fetchCartData();
  }, []);


  useEffect(() => {
    filterTimeSlots();
  }, [availableTimeSlots]);


  useEffect(() => {
    if (startDate && serviceId) {
      const formattedDate = startDate.toISOString().slice(0, 10); 
      fetchTimeSlots(formattedDate);
    }
  }, [startDate, serviceId]);

  const fetchCartData = async () => {
    try {
      setIsLoading(true);
      const res = await post('api/cart/items', { userToken });
      if (res.success) {
        setCartItems(res.data);
        const serviceItem = res.data.find(item => item.type === "Service");
        const bloodService = res.data.find(item => item.category === "report-category");
        if (serviceItem) setServiceId(serviceItem.serviceId);
        if (bloodService) setIsBloodDraw(true);
      } else {
        setCartItems([]);
        setNotFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      setCartItems([]);
      setNotFound(true);
      setIsLoading(false);
    }
  };
  const calculateTotalDuration = () => {
    const filteredServices = cartItems.filter(service => service.type === "Service");

    const totalDuration = filteredServices.reduce((total, service) => {
      return total + Number(service.duration);
    }, 0);

    return totalDuration;
  
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: '' });
  };

  const validateAge = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
    const isOver18 = age > 18 || (age === 18 && monthDiff >= 0);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setIsOver18(isOver18);

    handleInputChange({ target: { name: 'dob', value: formattedDate } });
  };

  const handleDateSelect = async (date) => {
    setDateSelectLoading(true);
    setStartDate(date);
    setIstimeSlotErr(false);
    setSelectedTimeSlot(null)
  
    // Use moment.js to format the date as YYYY-MM-DD in local time
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedDateUk = moment(date).format('DD/MM/YYYY');
    setSelectedDate(formattedDateUk);
    await fetchTimeSlots(formattedDate);
    handleInputChange({ target: { name: 'date', value: formattedDate } });
  
    if (timeSlotsRef.current) {
      timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchTimeSlots = async (date) => {
    try {
      setDateSelectLoading(true);
      const items = cartItems.find(item => item.type === "Service");
      const res = await post('api/booking/time/slots', { serviceId, providerId: 2, date, items});
      if (res.success && res.data) {
        setAvailableTimeSlots(res.data);
        setIsDateSelected(true);
      } else {
        setAvailableTimeSlots([]);
        setIsDateSelected(false);

      }
      setDateSelectLoading(false)

    } catch (error) {
      setAvailableTimeSlots([]);
      setIsDateSelected(false);
      setDateSelectLoading(false)

    }
  };

  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:00`;
  };

  const filterTimeSlots = () => {

    if(availableTimeSlots && availableTimeSlots.length > 0){
      const totalDuration = calculateTotalDuration();
      const slotDuration = 15; // assuming each slot is 15 minutes
  
      const timeSlotsInMinutes = availableTimeSlots.map(convertTimeToMinutes);
      const requiredSlots = totalDuration / slotDuration;
      const validSlots = [];
  
      for (let i = 0; i <= timeSlotsInMinutes.length - requiredSlots; i++) {
        let isValid = true;
  
        for (let j = 0; j < requiredSlots; j++) {
          if (timeSlotsInMinutes[i + j] !== timeSlotsInMinutes[i] + j * slotDuration) {
            isValid = false;
            break;
          }
        }
  
        if (isValid) {
          validSlots.push(availableTimeSlots[i]);
        }
      }

      const newlyFiltered = validSlots.filter(slot => slot !== undefined);
      setFilteredTimeSlots(newlyFiltered);
    }else{
      setFilteredTimeSlots([]);
    }

  };


  // const handleTimeSlotSelect = (timeSlot) => {

  //   setSelectedTimeSlot(timeSlot);
  //   handleInputChange({
  //     target: {
  //       name: 'time',
  //       value: timeSlot
  //     }
  //   });
  //   setIstimeSlotErr(false)
  // };

  const handleTimeSlotSelect = (timeSlot) => {
    const selectedSlotMinutes = convertTimeToMinutes(timeSlot);
    const totalDuration = calculateTotalDuration();
    const slotDuration = 15; // Assuming each slot is 15 minutes
    const requiredSlots = Math.ceil(totalDuration / slotDuration);
  
    const validSlots = Array.from({ length: requiredSlots }, (_, i) => 
      convertMinutesToTime(selectedSlotMinutes + i * slotDuration)
    );
  

  
    // Check if all valid slots are available
    const allSlotsAvailable = validSlots.every(slot => availableTimeSlots.includes(slot));
  
    if (allSlotsAvailable) {
      setSelectedTimeSlot(timeSlot);
      setConsistentTimeSlots(validSlots);
      handleInputChange({ target: { name: 'time', value: timeSlot } });
      handleInputChange({ target: { name: 'slots', value: validSlots } });
      setIstimeSlotErr(false);
    } else {
      console.log('Selected slots do not exist in the available list');
      setIstimeSlotErr(true);
    }
  };
  
  

  const calculateSubtotal = () => {
    let subtotal = 0;
    if(cartItems.length !==0){
      cartItems.forEach((item) => {
        if (item.type === 'Service') {
          subtotal += item.price;
        } else if (item.type === 'Add-Ons') {
          subtotal += item.discountPrice;
        }
      });
    }


    return subtotal;
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    let bloodDrawFee = 0; 
    if(isBloodDraw){
      bloodDrawFee = 30; 
    } 
    const total = subtotal + bloodDrawFee;
    return total;
  };

  const validateFormData = () => {
    const errors = {};

    // Check for required fields
    Object.keys(formData).forEach((key) => {
      if (!['date', 'time', 'userToken', 'slots'].includes(key) && !formData[key]) {
        errors[key] = 'This field is required';
      }
    });

    // Additional validations
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (formData.phone && !nineDigitRegex.test(formData.phone)) {
      errors.phone = 'Phone number must be at least 9 digits';
    }

    return errors;
  };
  const validateCheckoutForm = () => {
    const newErrors = validateFormData();
    setInputErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return false;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return false;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setIsSubmit(false);
      return false;
    }

    return true;
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsSubmit(true);

    const newErrors = validateFormData();


    setInputErrors(newErrors);
    
    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setIsSubmit(false);
      return;
    }

    try {
      const res = await post('api/booking/create', formData);
      console.log(res);
      if(res.success){
        MakePayment(res.id);
        setIsSubmit(false);

      }else{
        
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error
    } finally {
      setIsSubmit(false);
    }
  };


  const MakePayment = async (id)=>{
    const stripe = await loadStripe("pk_test_51IjhJTFHLnGWPWc3sdTdCkcNo3pBg55BaWV6M9oOkv6z9KSe1aZdvVKtVXICL13G1m3GbnevZTlY3qLOQ26yzCfo00H53DuljF");
    const result = stripe.redirectToCheckout({
      sessionId:id
    })
    if(result.error){
      console.log(result.error)
    }
  }


  return (
    <>
      {/* <Header /> */}

        <main id="content" className="wrapper layout-page">
              {/* <div className='bg-banner'> */}
                  <Navbar  />
                  <BreadCrumb title="Checkout" pageName="Checkout" bgImg={`${process.env.REACT_APP_BASE_URL}/assets/images/banner/banner-sample.jpg`} />
              {/* </div> */}
              <div className="container">
                  {isLoading && <PageLoader />} 
                  {cartItems.length > 0 && (
                  <div className="row" style={{marginTop:'50px',paddingBottom:'50px'}}>
                    <div className="col-12">
                    <h4 style={{marginBottom:'40px',marginTop:'40px'}}>Billing Details</h4>

                    </div>
                      <div className="col-lg-8 col-md-8 col-sm-12">
                          <BillingDetail   
                            formData={formData}                      
                            handleInputChange={handleInputChange}
                            inputErrors={inputErrors}
                            validateAge={validateAge}
                            isOver18={isOver18}
                            firstErrorRef={firstErrorRef}
                          />
                          <h4 className="" style={{marginBottom:'40px',marginTop:'40px'}}>Select Booking Date & Time</h4>
                          {isTimeSlotErr && 
                            <p className="mb-5" style={{color:'red',fontSize:'12px'}}><i className="fa fa-warning"></i> Please choose time for your appointment</p>
                          }
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <BookingDatePicker 
                                startDate={startDate}
                                handleDateSelect={handleDateSelect}
                                currentDate={new Date()}
                                containerRef={containerRef}
                                isOpen={isOpen}


                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 timeslot-section" ref={timeSlotsRef}>

                              {dateSelectLoading ? (
                                <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                                  <Spinner  variant="warning" animation="grow" size="xl" className="mr-2" />
                                </div>
                              ):(
                                <>
                                  <div >
                                  <TimeSlotList 
                                    selectedTimeSlot={selectedTimeSlot}
                                    handleTimeSlotSelect={handleTimeSlotSelect}
                                    timeSlots = {filteredTimeSlots} 
                                  />
                                </div>
                                </>
                              )}

                            </div>
                          </div>

                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        {/* <Elements stripe={stripePromise}> */}
                          <OrderSummary 
                            handleSubmit={handleSubmit}
                            cartItems={cartItems}
                            calculateSubtotal={calculateSubtotal}
                            calculateTotal={calculateTotal}
                            isSubmit={isSubmit}
                            isBloodDraw={isBloodDraw}
                            filteredTimeSlots={filteredTimeSlots}
                            selectedDate = {selectedDate}
                            selectedTimeSlot = {selectedTimeSlot}
                            validateCheckoutForm = {validateCheckoutForm}
                          />
                        {/* </Elements> */}
                      </div>
                  </div>
                  )}

              </div>
              <Footer />

        </main>

    </>
  );
};

export default Checkout;
