import React, {useEffect, useState } from 'react';

const TextWithReadMore = ({ text = '', wordLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [containerStyle, setContainerStyle] = useState({ height: '150px', overflow: 'hidden' });
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    if (isExpanded) {
      // Expand the div when "Read more" is clicked
      setContainerStyle({ height: 'auto', overflow: 'visible' });
    } else {
      // Collapse the div when "Read less" is clicked
      setContainerStyle({ height: '150px', overflow: 'hidden' });
    }
  }, [isExpanded]);


  // Check if the text is empty or not defined
  if (!text || text.length === 0) {
    return null; // Or you can return some default fallback UI
  }

  const truncatedText = text.length > wordLimit ? text.slice(0, wordLimit) + '..' : text;

  return (
    <>
    <div className='data-body' style={containerStyle}>
      <p className='fw-normal metropill'>
        <span className='metropill'>
          {isExpanded ? text : truncatedText}
          {text.length > wordLimit && (
            <span
              className="read-more metropill"
              onClick={handleToggle}
              style={{ color: '#e2b9b3', cursor: 'pointer', fontSize: '15px', fontWeight: '600' }}
            >
              {isExpanded ? 'Read less' : 'Read more'}
            </span>
          )}
        </span>
      </p>
    </div>
    </>
  );
};

export default TextWithReadMore;
